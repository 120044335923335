import { useEffect } from "react";
import { useAuthContext, useInstanceContext } from "../../context";
import { InstancesList } from "../instances/instancesList";
import { Button } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";

export const ClientDashboard = () => {
  const { user, setUser } = useAuthContext();
  const { getUserInstances } = useInstanceContext();
  const navigate = useNavigate();
  useEffect(() => {
    getUserInstances(user?.id!);
  }, []);
  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      alert("User ID copiado al portapapeles!");
    } catch (err) {
      alert("Fallo al copiar el User ID");
    }
  };
  const closeSession = () => {
    setUser(undefined);
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("token");
    navigate("/");
  };
  return (
    <div className="flex flex-col items-center justify-center min-h-screen py-2 bg-default-100">
      <Button
        onClick={closeSession}
        style={{ position: "absolute", top: "0", right: "2rem" }}
        className="mt-4 bg-red-900 hover:bg-red-400 text-white hover:text-white"
        fullWidth={false}
      >
        Cerrar sesión
      </Button>
      <div className="py-10 px-8 rounded-2xl flex flex-col justify-center items-center bg-gradient-to-tr from-primary to-secondary text-white shadow-lg">
        <div className="text-lg text-white">
          Hola {user?.name}, bienvenido a CYT.
        </div>
        <div className="text-lg font-light text-sm text-white my-3">
          Tu ID de usuario es:
        </div>
        <div className="flex items-center justify-center space-x-2">
          <span>{user?.id}</span>
          {user?.id && (
            <button
              onClick={() => copyToClipboard(user.id!)}
              className="ml-2 p-1 px-3 border rounded text-white bg-purple-900 hover:bg-purple-500"
            >
              Copiar
            </button>
          )}
        </div>
        <InstancesList />
      </div>
    </div>
  );
};
