import { Button, Image, Input } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { useAuthContext, useInstanceContext } from "../context";
import { useNavigate } from "react-router-dom";
import FacebookIcon from "../assets/icons/facebookIcon";
import {
  LoginErrorInterface,
  loginInterface,
  metaLoginInterface,
} from "../interfaces";
import InstagramIcon from "../assets/icons/InstagramIcon";
export default function Home() {
  const {
    loginWithMeta,
    isLoading,
    user,
    setUser,
    loginWithEmailAndPassword,
  } = useAuthContext();
  const { getMetaInstances } = useInstanceContext();
  const [loginSwitch, setLoginSwitch] = useState(false);
  const [email, setEmail] = useState("");
  const [emailValidation, setEmailValidation] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState(false);
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    if (user !== undefined && user.statusCode === 200) {
      if (user.role === "meta") getMetaInstances();
      navigate("/welcome");
    }
  }, [getMetaInstances, navigate, user]);
  const loginValidation = () => {
    setEmailValidation(!/\S+@\S+\.\S+/.test(email));
    setPasswordValidation(password.length < 6 || password.trim() === "");
    const validation =
      !/\S+@\S+\.\S+/.test(email) &&
      (password.length < 6 || password.trim() === "");
    return !validation;
  };
  const login = async () => {
    let userLogin: loginInterface = {
      email: email,
      password: password,
    };
    if (loginValidation()) {
      let response = await loginWithEmailAndPassword(userLogin);
      if (response.statusCode === 200) {
        let userResponse: metaLoginInterface = response as metaLoginInterface;
        setUser(userResponse);
        sessionStorage.setItem("user", JSON.stringify(userResponse));
        sessionStorage.setItem("token", userResponse.access_token!);
      } else {
        let error: LoginErrorInterface = response as LoginErrorInterface;
        setErrorMessage(error.message);
      }
    }
  };
  return (
    <main className="flex flex-col justify-center items-center h-screen">
      <Image
        width={300}
        src={
          "https://cytcomunicaciones.com/wp-content/uploads/2022/10/logo_cytfinalweb-12.webp"
        }
        alt={"CYT"}
        style={{ paddingBottom: 25 }}
      />
      {loginSwitch ? (
        <section className="mt-8 flex flex-col gap-2" style={{ width: 300 }}>
          <Input
            label={"Email"}
            value={email}
            variant="bordered"
            isInvalid={emailValidation}
            errorMessage={
              emailValidation &&
              "Por favor, valide que el usuario sea correcto."
            }
            onChange={(e) => setEmail(e.target.value)}
          />
          <Input
            label={"Password"}
            value={password}
            type="password"
            variant="bordered"
            isInvalid={passwordValidation}
            errorMessage={
              passwordValidation &&
              "Por favor, valide que la contraseña sea correcta."
            }
            onChange={(e) => setPassword(e.target.value)}
          />
          {errorMessage && (
            <span className="text-red-500 text-sm">{errorMessage}</span>
          )}
          <Button
            className=" align-middle px-6 py-6 text-white"
            color="secondary"
            isLoading={isLoading}
            onClick={() => login()}
          >
            Ingresar
          </Button>
          <Button
            className=" align-middle px-6 py-6 text-white"
            color="primary"
            isLoading={isLoading}
            onClick={() => setLoginSwitch(false)}
          >
            Cancelar
          </Button>
        </section>
      ) : (
        <section className="mt-8 flex flex-col gap-3">
          <Button
            className="align-middle px-6 py-6 text-white"
            color="primary"
            isLoading={isLoading}
            onClick={() => loginWithMeta(false)}
          >
            {FacebookIcon({ width: "40", height: "40", color: "white" })}
            Iniciar sesión con Facebook
          </Button>
          <Button
              className="align-middle px-6 py-6 text-white"
              color="secondary"
              isLoading={isLoading}
              onClick={() => loginWithMeta(true)}
          >
            {InstagramIcon({ width: "40", height: "40", color: "white" })}
            Iniciar sesión con Instagram y Facebook
          </Button>
          <Button
              className=" align-middle px-6 py-6 text-white"
              color="secondary"
            isLoading={isLoading}
            onClick={() => setLoginSwitch(true)}
          >
            Iniciar sesión con Usuario y Contraseña
          </Button>
        </section>
      )}
    </main>
  );
}
